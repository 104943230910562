import { Box, FormField, TextInput } from 'grommet';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import useBus from 'use-bus';

import { usePlatformMutation } from '../hooks';
import { useSessionStore } from '../store';

const Hello = ({ id, settings }) => {
  const [name, setName] = useState(settings?.name || '');

  const queryClient = useQueryClient();

  const mutation = usePlatformMutation(`/userworkplace/updatesettings/${id}`, {
    method: 'POST',
  });

  const session = useSessionStore((state) => state.session);

  useBus(
    'sidebar.toggle',
    (event) => {
      if (event.open || !name) return;

      mutation.mutateAsync({ name }).then(() => {
        queryClient.setQueryData(`/userworkplace/cards:${session.UserId}`, (oldData) => {
          const item = oldData?.Data?.find((item) => item.Id == id);

          if (item?.settings) item.settings.name = name;

          return oldData;
        });
      });
    },
    [name],
  );

  return (
    <Box fill="vertical" overflow="auto" align="left" flex="grow">
      <FormField label="Name">
        <TextInput onChange={(event) => setName(event.target.value)} value={name}></TextInput>
      </FormField>
    </Box>
  );
};

export default Hello;
