import React from 'react';

const Empty = ({ metadata, data }) => {
  return (
    <>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
      <span>body</span>
    </>
  );
};

export default Empty;
