import React from 'react';
import { Box, CheckBoxGroup } from 'grommet';

const Empty = ({ metadata, data }) => {
  return (
    <Box fill="vertical" overflow="auto" align="left" flex="grow">
      <div>Filter leads by region:</div>
      <CheckBoxGroup options={['USA/Canada', 'EMEA', 'Other']} value={['USA/Canada', 'EMEA', 'Other']} />
    </Box>
  );
};

export default Empty;
